import * as Cookie from 'js-cookie';
import { parse } from 'query-string';
// eslint-disable-next-line no-unused-vars
import CurrentCountry from './CurrentCountry';
import { clientApi } from '@phoenix/clientApi';
import laravelRoute from 'ziggyRoute';

/**
 * GeoLocation module; calls a server endpoint to check if visitors are on a domain that corresponds
 * to their IP and if not shows a proposition banner with links to the correct domain.
 */
export default abstract class AbstractGeoLoc {
  protected COUNTRY_PROPOSITION_BANNER_SELECTOR = '#country-proposition-banner';
  protected COUNTRY_PROPOSITION_ITEM_SELECTOR = '.country-proposition-item';
  protected COOKIE_NAME = 'geoloc-country-code';
  /**
   * Reference to the CurrentCountry instance
   */
  currentCountry: CurrentCountry;

  constructor(currentCountry: CurrentCountry) {
    this.currentCountry = currentCountry;
    this.locateUserAndShowPropositionBanner();
  }

  /**
   * Try to get the user's IP and show the proposition banner if necessary
   * @returns {Promise<void>}
   */
  async locateUserAndShowPropositionBanner() {
    // If we have a cookie already set it means the geo loc was already
    // performed. We can't abort early as we have to gp through the update method
    let countryCookie = Cookie.get(this.COOKIE_NAME);

    // To help with local development, provide an override mechanism via a
    // query string parameter.
    const url = new URLSearchParams(window.location.search);
    if (url.get(window.Phoenix.config.GEO_LOC_OVERRIDE_PARAM)) {
      countryCookie = url.get(window.Phoenix.config.GEO_LOC_OVERRIDE_PARAM);
      Cookie.remove(this.COOKIE_NAME);
    }

    if (!countryCookie || countryCookie.length === 0) {
      countryCookie = await this.locateUserByIP();
      if (!countryCookie) {
        return;
      }
    }

    this.showLocalisationsInPropositionBanner(countryCookie);
    if (this.shouldShowBanner(countryCookie)) {
      this.showPropositionBanner();
    }
    Cookie.set(this.COOKIE_NAME, countryCookie, { expires: 30, secure: true });
    this.currentCountry.update();
  }

  /**
   * Perform and XHR to a route that isn't cached by the CDN and
   * In a local development environment, this will return the VM's IP, which is why we provide
   * an override mechanism, which reads the IP from a query string parameter.
   * @returns {Promise}
   */
  async locateUserByIP() {
    try {
      const { data } = await clientApi.get(laravelRoute('api.xhr.getCountryCode'));
      return window.Phoenix.existingCountries.includes(data) ? data : 'INT';
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(`Could not locate user by IP (${error})`);
      return null;
    }
  }

  /**
   * Show the proposition banner inside a promotion block
   */
  abstract showPropositionBanner(): void;

  /**
   * Determine whether the country proposition banner should be shown
   * @param countryCodeFromIP
   * @returns {boolean}
   */
  shouldShowBanner(countryCodeFromIP) {
    // Condition 1: The geolocated IP must be different from the site's currentCountry code
    const hasIpFromDifferentCountry =
      this.currentCountry.defaultCountryCode.toUpperCase() !== countryCodeFromIP.toUpperCase();
    // Condition 2: The user should not already have a currentCountry cookie
    const doesNotHaveCookie = typeof Cookie.get(this.COOKIE_NAME) === 'undefined';
    // Condition 3: The user should not have #setCurrentScope=xx in the URL
    const doesNotHaveHash = typeof parse(location.hash).setCurrentScope === 'undefined';

    return hasIpFromDifferentCountry && doesNotHaveCookie && doesNotHaveHash;
  }

  showLocalisationsInPropositionBanner(countryCode) {
    const banner = document.querySelector(this.COUNTRY_PROPOSITION_BANNER_SELECTOR);
    const elements = document.querySelectorAll(
      `${this.COUNTRY_PROPOSITION_BANNER_SELECTOR} ${this.COUNTRY_PROPOSITION_ITEM_SELECTOR}[data-code="${countryCode}"]`
    );

    Array.from(elements).forEach((element) => {
      element.classList.remove('hidden');
    });

    if (banner) {
      banner.dispatchEvent(new Event('countryPropositionLocalisationsVisible'));
    }
  }
}
